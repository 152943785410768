<template>
    <div class="unordered">
        <a-drawer
            placement="top"
            height="calc(100vh)"
            :closable="false"
            :visible="visible"
            >
            <div class="unordered_content">
                <div class="closeBtn" @click="onClose">
                    <span>关闭</span>
                    <img
                        slot="cover"
                        alt="example"
                        src="@/assets/image/close.png"
                    />
                </div>
                <h1>服务机构&研究所</h1>
                <a-row type="flex">
                    <a-col :span="12" class="borderR" @click="open(item)" v-for="(item, i) in dataList" :key="i">
                        <div class="col_div">
                            <div class="left">
                                <img
                                    slot="cover"
                                    alt="example"
                                    :src="item.src"
                                />
                            </div>
                            <div class="right">
                                <h2>{{ item.title }}</h2>
                                <p>{{ item.content }}</p>
                            </div>
                        </div>
                    </a-col>
                </a-row>
                <a-divider class="divider">旗下站点</a-divider>
                <a-carousel arrows :dots="false" autoplay :slides-to-show="4" :slides-to-scroll="1">
                    <div
                        slot="prevArrow"
                        slot-scope="props"
                        class="custom-slick-arrow"
                        style="left: 10px;zIndex: 1"
                        >
                        <a-icon type="left" />
                    </div>
                    <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                        <a-icon type="right" />
                    </div>
                    <div class="carousel_view" v-for="(item, i) in imgData" :key="i">
                        <a :href="item.href ? item.href : 'javascript: void(0)'" :target="item.href ? '_blank' : ''">
                            <img
                            v-if="item.href"
                            slot="cover"
                            alt="example"
                            :src = "hovered === i ? item.hoverimg : item.img"
                            @mouseover="hovered = i"
                            @mouseout="hovered = null"
                            />
                            <img
                            v-else
                            slot="cover"
                            alt="example"
                            :src="item.img"
                            />
                        </a>
                        
                    </div>
                </a-carousel>
            </div>
        </a-drawer>
    </div>
</template>
<script>
import { get10 } from "@/api/index";
export default {
    name:'Unordered',
    data () {
        return {
            hovered: null,
            visible: false,
            dataList:[
                {
                    id: 1,
                    src: require('@/assets/image/sl.png'),
                    title: '标准文献馆',
                    content: '全省标准文献资源规划建设，全院信息化建设规划协调，传统业务的数字化赋能、转型与升级，标准数字化研究与应用，相关领域标准化研究应用与技术咨询的基础性业务部门。'
                },{
                    id: 2,
                    src: require('@/assets/image/ic.png'),
                    title: '物品编码部（大数据检测实验室）',
                    content: '编码标准化研究、咨询、宣传推广和数据系统产品质量检验的专职部门。'
                },{
                    id: 3,
                    src: require('@/assets/image/ti.png'),
                    title: '高新技术与产业标准化研究所',
                    content: '高新技术与产业标准化研究及技术服务的专职部门。'
                },{
                    id: 4,
                    src: require('@/assets/image/dr.png'),
                    title: '社会治理标准化研究所',
                    content: '政府治理、社会管理与公共服务标准化理论研究、决策咨询和技术服务的专职部门。'
                },{
                    id: 5,
                    src: require('@/assets/image/ti1.png'),
                    title: '现代服务业与政府管理标准化研究所',
                    content: '现代服务业和政府管理标准化研究及技术服务的专职部门。'
                },{
                    id: 6,
                    src: require('@/assets/image/is.png'),
                    title: '国际标准化研究所',
                    content: '技术性贸易措施、检验检测以及国际标准化领域研究和应用的专职部门。'
                },{
                    id: 7,
                    src: require('@/assets/image/is1.png'),
                    title: '质量发展与质量安全研究所',
                    content: '质量发展与质量安全研究及技术服务的专职部门。'
                },{
                    id: 8,
                    src: require('@/assets/image/re.png'),
                    title: '资源环境与绿色发展标准化研究所',
                    content: '资源环境与绿色发展标准化研究及技术服务的专职部门。'
                },{
                    id: 9,
                    src: require('@/assets/image/log.png'),
                    title: '商贸流通与供应链标准化研究所',
                    content: '商贸流通与供应链标准化研究及技术服务的专职部门。'
                },{
                    id: 10,
                    src: require('@/assets/image/dr.png'),
                    title: '事业发展部',
                    content: '院级层面业务战略落地执行，业务资源统筹协调，业务质效优化提升，客户资源拓展维护的部门。'
                },{
                    id: 11,
                    src: require('@/assets/image/sl.png'),
                    title: '标准化理论与战略研究所',
                    content: '科研管理、标准化基础理论研究、标准化政策研究与战略规划的专职部门。'
                }
            ],
            imgData:[
                {
                    img: '/static/数字标准馆.png',
                },
                {
                    img: '/static/HBGIP.png',
                },
                {
                    img: '/static/HBIT.png',
                },
                {
                    img: '/static/HBOSIT.png',
                },
                {
                    img: '/static/HBSCCDSP.png',
                },
                {
                    img: '/static/OVCTSI (2).png',
                },
                {
                    img: '/static/WechatIMG85.png',
                    href: 'http://whfs.tpddns.cn:8304/index',
                    hoverimg: '/static/WechatIMG85a.png'
                },
                {
                    img: '/static/dsjjcsys.png',
                    href: 'http://whfs.tpddns.cn:8302/index',
                    hoverimg: '/static/dsjjcsysa.png'
                },
                {
                    img: '/static/WechatIMG87.png',
                },
            ]
        }
    },
    created(){
    //   this.get10()
    },
    methods:{
        get10(){
            get10({visible_EQ:1,type_EQ:'enter',module_EQ:'index'}).then(res => {
                this.imgData = res.data.map(element => {
                    element.imgUrl = eval('('+element.imgUrl+')');
                    element.imgSrc = `/static/${element.imgUrl[0].name}`
                    return element
                });
            })
        },
        onClose(){
            this.visible = false
        },
        open(item){
            window.open(`${window.location.origin}/#/Association/${item.id}`, '_blank');
        }
    }
};
</script>
<style lang="less">
    .unordered_content{
        padding-top: 20px;
        padding-left: calc(50% - 580px);
        padding-right: calc(50% - 580px);
        h1{
            font-size: 20px;
            line-height: 43px;
            color: #333;
            margin-bottom: 20px;
            text-align: center;
            margin-top: 30px;
        }
        .borderR:nth-child(2n-1){
            border-right: 1px solid #333;
            .col_div{
                margin-right: 50px;
            }
        }
        .borderR:nth-child(2n){
            .col_div{
                margin:0 50px;
            }
        }
        .col_div{
            color: #999;
            display: inline-flex;
            align-content: flex-start;
            align-items: flex-start;
            justify-content: center;
            cursor: pointer;
            transition: all .3s;
            .left{
                margin-right: 40px;
            }
            .right{
                h2 {
                    font-size: 20px;
                    letter-spacing: 1px;
                    color: #fff;
                }

            }
        }
        .col_div:hover {
            opacity: .5;
        }
        .divider::before,.divider::after{
            border-color: #333;
        }
        .divider{
            font-size: 20px;
            color: #333;
        }
        .ant-carousel{
            .slick-slide {
                text-align: center;
                height: 150px;
                width: 186px;
                line-height: 72px;
                overflow: hidden;
            }
            .custom-slick-arrow {
                width: 25px;
                height: 25px;
                font-size: 25px;
                color: #fff;
                background-color: rgba(31, 45, 61, 0.11);
                opacity: 0.3;
            }
            .custom-slick-arrow:first-child {
                left: -30px;
            }
            .custom-slick-arrow:last-child {
                right: -30px;
            }
            .custom-slick-arrow:before {
                display: none;
            }
            .custom-slick-arrow:hover {
                opacity: 0.5;
            }
            .slick-slide h3 {
                color: #fff;
            }
            .carousel_view{
                display: flex !important;
                justify-content: center;
                align-items: center;
                height: 150px;
                img{
                    cursor: pointer;
                }
            }
        }
    }
</style>
