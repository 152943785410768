<template>
    <div class="association">
       <div class="association_l">
            <a-menu
                style="maxWidth: 200px"
                :default-selected-keys="defaultSelectedKeys"
                mode="inline"
                :inline-collapsed="collapsed"
                @click="handleClick"
                >
                <a-menu-item v-for="item in menuData" :key="item.id">
                    <a-icon :type="item.icon" /><span>{{ item.item }}</span>
                </a-menu-item>
            </a-menu>
       </div>
       <div class="association_r">
            <a-layout style="background-color: #fff;">
                <div class="department">
                    <h2>部门介绍</h2>
                    <p class="line"></p>
                    <div class="title">
                        <div v-for="(item, i) in departments.titleData" :key="i">
                            <h2>{{ item.h2 }}</h2>
                            <p>{{ item.p }}</p>
                        </div>
                    </div>

                    <p class="line"></p>
                    <div class="dutyList dutyListl">
                        <div class="title">
                            <h2>{{ departments.chengDan.h2 }}</h2>
                            <p>{{ departments.chengDan.p }}</p>
                        </div>
                    </div>
                    <div class="dutyList dutyListr">
                        <div class="title" v-if="departments.zhiNeng">
                            <h2>{{ departments.zhiNeng.h2 }}</h2>
                            <p>{{ departments.zhiNeng.p }}</p>
                        </div>
                        <div class="title" v-if="departments.lianLuo">
                            <h2>{{ departments.lianLuo.h2 }}</h2>
                            <p>{{ departments.lianLuo.p }}</p>
                        </div>
                    </div>
                </div>
                <div class="department">
                    <h2>核心业务</h2>
                    
                    <p class="line"></p>
                    <div class="img">
                        <img class="fixedWidth" v-for="(image, index) in departments.hexinyewuList" :key="index" :src="image.img" alt="">
                    </div>

                    

                    <!-- <div class="dutyList dutyListl">
                        <p v-for="(item, i) in departments.dutyListl" :key="i">{{ item }}</p>
                    </div>
                    <div class="dutyList dutyListr">
                        <p v-for="(item, i) in departments.dutyListr" :key="i">{{ item }}</p>
                    </div> -->
                </div>
                <div class="department" v-if="departments.hzjg">
                    
                    <h2>合作机构</h2>
                    <p class="line"></p>
                    <img class="fixedWidth" :src="departments.hzjg.img" alt="合作机构">
                </div>
                <div class="department" v-if="departments.imgList.length > 0">
                    <p class="line"></p>
                    <h2>优质案例</h2>
                    <p class="line"></p>
                    <div>
                        <div class="imgList">
                            <img 
                                v-for="(item, i) in departments.imgList" :key="i"
                                class="ImgOpen"
                                :class="imgListIndex === i?'selectedSty':''"
                                slot="cover"
                                alt="example"
                                @click="imgListClick(i,item)"
                                :src="item.src"
                            />
                        </div>
                        <h3>{{ imgListObj.h3 }}</h3>
                        <p>{{ imgListObj.p }}</p>
                    </div>                 
                </div>
            </a-layout>
       </div>
        <div class="collapsedBT" @click="toggleCollapsed">
            <a-icon :type="collapsed ? 'right' : 'left'" />
        </div>
    </div>
</template>

<script>
export default {
    name:'Association',
    data(){
        return {
            defaultSelectedKeys:[],
            collapsed: false,
            menuData:[{
                id: 1,
                icon: 'bank',
                item: '标准文献馆',
                departments: {
                    titleData:[{
                        h2:'从事',
                        p: '全省标准文献资源规划建设，全院信息化建设规划协调，传统业务的数字化赋能、转型与升级，标准数字化研究与应用，相关领域标准化研究应用与技术咨询的基础性业务部门。'
                    },{
                        h2:'致力于',
                        p: '建设并不断完善具有湖北特色的标准文献馆藏体系、研究指导全省行业标准体系建设工作、研究标准数据挖掘分析技 术、深入开展标准数据在各行业中的应用研究、做好标准化行政管理技术支撑、做好标准技术审评工作与标准实施效果 评价工作、深入开展应急管理、自然资源、农 （林） 等领域的标准化顶层设计与应用研究'
                    }],
                    dutyListl:[
                        '负责全省标准文献馆藏资源规划与建设',
                        '负责全省标准技术审评与实施效果评价',
                        '负责全省标准化管理工作技术支撑与数据支持',
                        '负责申报和承担标准文献基础应用、标准数据深化处理、标准数据应用、应急管理、自然资源、农 （林） 等领域标准化科研项目'
                    ],
                    dutyListr:[
                        '负责在标准文献基础应用、标准数据深化处理、标准数据应用、应急管理、自然资源、农 （林） 等领域开展各项标准研制',
                        '负责为应急管理、自然资源、农 （林） 等领域的行政管理部门、企事业单位提供标准化解决方案和标准化技术咨询服务',
                    ],
                    imgList:[{
                        src:require('@/assets/image/content/page2_2_1.png'),
                        h3:'助力我省安全生产精细化管理与标准化建设',
                        p:'协助湖北省应急管理厅筹备成立“湖北省安全生产 标准化技术委员会”, 会同相关行业部门、重点院 校、企业讨论通过了 《湖北省安全生产标准化技术 要员会章程》；组织专班运用标准化理论与方法、 搭建了覆盖全行业领域的安全生产标准体系 -《企 业安全生产监管标准》汇编 , 基本涵盖了国内安全 生产标准的重要信息 , 为监管部门的管理和企业 的安全生产提供了技术支撑。标准数据推送系统的 建立 , 实现了与湖北安全生产综合信息平台的数 据对接与共享。'
                    },{
                        src:require('@/assets/image/content/page2_2_2.png'),
                        h3:'标准大数据应用为湖北高质量发展奠定基础',
                        p:'面对全球范围内运用大数据推动经济发展、完善社 会治理、提升政府服务和监管能力的大趋势，在标准化 研究领域，运用大数据技术对各种数据进行筛选、 分析，可以为标准化工作提供强有力的指导和支持。 自 2016 年起，标准文献馆每年都会出具针对上一 年度的 《湖北省标准大数据分析报告》，围绕《湖北 省国民经济和社会发展第十三个五年规划纲要》、湖 北省人民政府《关于深化标准化工作改革创新的意 见》 确定的发展目标和重点任务，通过大数据技术 全面分析和立体展示我省标准化工作开展情况， 认真总结工作成果及经验，探讨标准化与经济和社会 发展的关系，为湖北省未来标准化工作的开展方向 提供了权威的数据支撑。'
                    },{
                        src:require('@/assets/image/content/page2_2_3.png'),
                        h3:'标准推动“厕所革命”',
                        p:'《农村无害化厕所建造技术指南》地方标准的编制是 我馆参与湖北省“厕所革命”的具体工作，在标准编制 过程中，广泛征求意见，确保标准的适用性和可操作性， 推动我省“厕所革命”相关产品生产企业按新标准 提升产品质量。该标准的实施意味着农村无害化厕所 新建、改建及验收，有了统一标准。同时，我省农村厕改 工作的招标、采购、竞争性谈判以及督查、验收等 工作都将以此为标准。确保到 2020 年我省将全面 完成 200 万户农户无害化厕所新建、130 万户农户 厕所改造提升以及 25063 座农村公共厕所建设 改造任务。'
                    }],
                    chengDan: {
                        h2:'承担',
                        p: '国家标准验证点（桥梁智能建造）\n国家强制性标准实施情况统计分析点（农作物种子）\n标准数字化技术与应用湖北省工程研究中心\n湖北省马术产业标准化工作组\n医疗服务标准化研究中心'
                    },
                    zhiNeng: {
                        h2:'职能、公益',
                        p: '标准文献规划建设\n标准文献检索与阅览\n专业领域技术标准专题数据库与语料库建设\n湖北省地方标准立项查新'
                    },
                    hzjg: {
                        img:"/static/department/标准文献馆.jpeg"
                    },
                    hexinyewuList:[{
                        img:"/static/department/bzwxg1.png"
                    },{
                        img:"/static/department/bzwxg2.png"
                    },{
                        img:"/static/department/bzwxg3.png"
                    },{
                        img:"/static/department/bzwxg4.png"
                    }],
                }
            },{
                id: 2,
                icon: 'barcode',
                item: '物品编码部（大数据检测实验室）',
                departments: {
                    titleData:[{
                        h2:'从事',
                        p: '编码标准化研究、咨询、宣传推广和数据系统产品质量检验的专职部门。'
                    },{
                        h2:'致力于',
                        p: '物品编码、知识产权（地理标志）、特种设备、数据系统等领域标准化前沿理论、解决方案以及标准化成果的应用研究。'
                    },{
                        h2:'负责',
                        p: '组织、协调、管理全省物品编码工作；\n为各行业领域的信息化软件、数据系统产品提供专业的软件检验检测和技术咨询服务；\n全省商品条码、产品电子代码、全球位置码、通用物资编目和医疗器械唯一编码等通用物品编码的注册管理与维护；\n全省商品条码标准化产品数据采集与应用；\n全省条码质量标准符合性检测与技术咨询；\n全省商品条码相关法规与技术标准的宣贯、培训与服务。'
                    }],
                    dutyListl:[
                        '中国物品编码中心湖北分中心和湖北省条码标识质量监督检验站的工作职能承担湖北省地理标志产品标准化技术委员会秘书处工作职责',
                        '商品条码、产品电子代码、全球位置码等通用物品编码的注册管理工作，为用户提供 GS1 编码应用咨询服务',
                        '全球商品数据同步工作，为用户提供全球通用的 GS1、EPC 数据池技术咨询服务',
                        '条码质量检验，商品条码印刷资格认定工作，为用户提供条码标识的标准符合性质量检测服务，推动条码标识的应用和印刷质量水平'
                    ],
                    dutyListr:[
                        '物品编码及自动识别技术相关标准的宣贯培训工作，为用户提供物品编码技术咨询服务及标准化解决方案',
                        '地理标志产品标准化相关宣贯、培训、研究及推广工作',
                        '物品编码及相关标准化研究',
                        '条码及自动识别技术的研发及推广工作'
                    ],
                    imgList:[{
                        src:require('@/assets/image/content/page2_3_1.png'),
                        h3:'条码微站助力备战“双十一”',
                        p:'仙桃市沙湖珠沙红蛋业有限公司为备战一年一度的 天猫“双十一”活动，在前期准备了大量新产品投入 市场，但屡次接到消费者关于扫码信息不正确的投诉， 使其品牌建设和渠道销售受到严重影响。 中国物品编码中心湖北分中心工作人员通过现场查 询企业提供的问题产品，帮助企业将近 200 种产品 的详细信息逐一在“中国商品信息服务平台”中填报， 并提交错误信息修改申请，帮助企业将原简单的产 品名称、规格等信息完善为标准化的商品数据资产。为 了解决网上企业推广销售的困难局面，珠沙红还加 入了中国物品编码中心“条码微站”平台，在互动营 销、产品销售与宣传推广上取得了极大成功。'
                    },{
                        src:require('@/assets/image/content/page2_3_2.png'),
                        h3:'绿色通道解决审核燃眉之急',
                        p:'宜昌日进包装有限公司在申报商品条码印制资格证之 前，对条码印刷的相关知识不甚了解，其寄送样品所印制 条码符号质量一直未达到中国物品编码中心的要求。但 是该公司迫切需要在招标活动中提供商品条码印刷资格 相关资质证书，不然将面临巨大经济损失。中国物品编码 中心湖北分中心 （湖北省条码标识质量监督检验站） 了解 到这一情况后，建议企业主管领导召集相关设计、制版、检 测、机长等技术人员和业务人员与湖北分中心专业技术 人员共同研究讨论问题原因，学习商品条码相关知识和 法律法规，并在企业设计、印刷现场不断进行试验，通过调 整油墨浓度、粘度和滚筒压力、温度等参数，较好地解决了 条码印刷质量问题，最终在湖北分中心随机抽样的瓦楞 纸印刷品检测的符号等级为 2.0/06/660，符合认定要求。 湖北分中心考虑到该公司的具体情况，申请中国物品编 码中心绿色通道，经编码中心检测审核后，于一周时间内 批准发证，解决了企业燃眉之急。'
                    },{
                        src:require('@/assets/image/content/page2_3_3.png'),
                        h3:'真诚服务挽回百万损失',
                        p:'湖北回天胶业股份有限公司是襄阳一家生产润滑剂、 润滑油等产品的知名企业，1999 年注册了商品条码。 2014 年变更为湖北回天新材料股份有限公司，人事 做了极大调整，导致商品条码到期。直到 2017 年 5 月， 在整理公司资质证件时，该公司工作人员发现公司 《中国商品条码系统成员证书》 已过期，而公司还在继 续使用原条码，并且仓库存有超百万的产品包装。如 果作废，极大的影响公司发展，损害公司的形象。随即 该公司立即向中国物品编码中心湖北分中心通报相 关情况，湖北分中心了解了实际情况后，考虑到企业 大量库存包装面临作废的现实问题，为企业提供商品 条码培训，指导企业办理商品条码原号注册和名称变 更手续，在最短时间内帮助企业恢复了商品条码原 号，规避了企业商品条码号过期导致的风险及大量包 装报废的严重后果，促进企业的健康发展。挽回企业 直接经济损失超过 120 万元。'
                    }],
                    chengDan: {
                        h2:'承担',
                        p: '中国物品编码中心湖北分中心\n湖北省条码标识质量监督检验站\n湖北省数据系统产品质量检验检测中心\n湖北省地理标志产品标准化技术委员会\n湖北省源数据工作室'
                    },
                    hzjg: {
                        img:"/static/department/物品编码部.jpeg"
                    },
                    hexinyewuList:[{
                        img:"/static/department/wpbmb1.png"
                    },{
                        img:"/static/department/wpbmb2.png"
                    },{
                        img:"/static/department/wpbmb3.png"
                    },{
                        img:"/static/department/wpbmb4.png"
                    },{
                        img:"/static/department/wpbmb5.png"
                    }],
                }
            },{
                id: 3,
                icon: 'deployment-unit',
                item: '高新技术与产业标准化研究所',
                departments: {
                    titleData:[{
                        h2:'从事',
                        p: '高新技术与产业标准化研究及技术服务的专职部门。'
                    },{
                        h2:'致力于',
                        p: '围绕新一代信息技术、测绘地理空间信息、高端装备、智能制造、新材料、新能源、城市建设文化遗产保护、生物医药、科技服务等领域，开展科技成果转化、战略设计、标准研制、标准评估以及标准化成果应用的理论与科学研究。'
                    }],
                    dutyListl:[
                        '跟踪国内外战略性新兴产业、高新技术的前沿动态，开展高新领域的标准化理论与实践研究',
                        '为政府相关职能部门布局新兴产业、引领行业发展提供决策参考和标准化技术支撑',
                        '为企业掌握规则话语权、抢占市场提供标准化解决方案',
                        '重点开展激光、地球空间信息、新一代信息技术（物联网、人工智能、智慧城市、电子政务、大数据）、智能制造、集成电路、轨道交通、新材料、航空航天'
                    ],
                    dutyListr:[
                        '为高新技术企业及研究机构提供科研成果转化、高新技术产业化、标准制修订、标准体系建设等技术咨询服务；为科技初创型企业提供标准化工作全托管服务',
                        '提供“新技术成果 - 标准咨询 - 符合性检测认证 - 新产业”的孵化服务',
                        '以更好的促进高新技术转化、推动高新产业经济发展为出发点，探索开展专利与标准结合、标准评估、标准潜力分析等标准化应用研究',
                    ],
                    imgList:[{
                        src:require('@/assets/image/content/page2_4_1.png'),
                        h3:'以标准打破国外垄断保护民族产业',
                        p:'为了增强我国光电子产业原材料的话语权，利用创 新基地搭建的TC/SC工作站，为企业与标准化委员 会提供在线的标准供需对接服务，协助晶星科技公 司开展二氯二氢硅和四氯化硅技术成果转化为技术 标准工作。转化形成的 《电子工业用二氯二氢硅》 和 《电子工业用四氯化硅》 两项国家标准，填补了我国 缺乏半导体原材料生产工艺和分析检测技术的空 白，改变了以往国产产品因缺标准无法直接销售，都 采用“先出口再进口的曲线运作模式”，解决了我国 半导体和集成电路行业受制约的局面。该标准将改 变目前先出口再进口的曲线模式，预计每年能为我 国节省进口二氯二氢硅总额约1亿元，节省进口四氯 化硅总额8.5亿元。'
                    },{
                        src:require('@/assets/image/content/page2_4_2.png'),
                        h3:'以标准促进传统行业转型升级',
                        p:'为促进传统锻造耗能产业转向全球产业链条中的高 附加值产业，解决湖北三环锻造有限公司在标准研 制和标准实施过程中遇到的难题，多次组织相关专 家深入企业，通过标准化公共服务平台和基地标准 供需服务的线上服务平台，成功解决锻造企业产能 过剩、同质化竞争严重、自动化和智能化程度偏低等 难题。转化形成的 《模锻件数字化车间生产过程控 制系统功能要求》、《模锻件锻造过程信息追溯通用 要求》、《汽车复杂锻件余热利用技术规范》 等系列 湖北省地方标准，固化了锻造领域智能化生产的技 术路线，推动智能技术改造传统制造业、推动工业化 和信息化的深度融合，进一步促进我省汽车零部件 锻造领域走可持续发展和绿色发展的道路。'
                    },{
                        src:require('@/assets/image/content/page2_3_3.png'),
                        h3:'以标准突显湖北行业领先地位',
                        p:'网络直播作为发展迅速、市场规模急剧扩张的新兴产 业，在武汉，以斗鱼直播为龙头已形成了全行业完整 的产业链。为解决该行业无标准可依、乱象频出的现 象，高新所推动斗鱼直播联合武汉高校、以及软件、新 媒体行业等研制发布了 《网络直播平台管理规范》 以及 《网络直播主播管理规范》，两个网络直播团体 标准，包含直播平台的主播监控、账号监管、平台巡查 等，以及主播的着装要求、准入标准、直播内容等方面 的规范。据行业预测2019年全国网络直播行业产值 将达到1000亿元，作为我国正式出台的首批网络直 播团体标准，将对推动该行业步入良性发展轨道有着 重要意义，同时，“网络直播标准发布”的热度，使湖北 网络直播产业成为了行业引领者。'
                    }],
                    chengDan: {
                        h2:'承担',
                        p: '湖北省机械标准化技术委员会\n湖北省数字技术标准化技术委员会\n湖北省电子信息标准化技术委员会\n湖北省电池标准化技术委员会\n全国信息技术标准化技术委员会智慧城市工作组成员单位\n全国信息技术标准化技术委员会大数据工作组成员单位'
                    },
                    hzjg: {
                        img:"/static/department/高新技术与产业标准化研究所.jpeg"
                    },
                    hexinyewuList:[{
                        img:"/static/department/gxjsycybzh1.png"
                    },{
                        img:"/static/department/gxjsycybzh2.png"
                    },{
                        img:"/static/department/gxjsycybzh3.png"
                    },{
                        img:"/static/department/gxjsycybzh4.png"
                    }],
                }
            },{
                id: 4,
                icon: 'idcard',
                item: '社会治理标准化研究所',
                departments: {
                    titleData:[{
                        h2:'从事',
                        p: '政府治理、社会管理与公共服务标准化理论研究、决策咨询和技术服务的专职部门。'
                    },{
                        h2:'致力于',
                        p: '营商环境、数字政府、数字社会、基层治理、综合治理、人力资源和社会保障、财政治理、机关事务管理、文体教育、公共安全与金融服务等领域的标准化理论政策、体制机制、标准体系研究，标准化成果应用及评价和公共服务质量提升等工作。'
                    }],
                    dutyListl:[
                        '负责市场开发、拓展、各业务部门商务信息收集整理、经济类收入合同的日常管理，合同登记'
                    ],
                    dutyListr:[],
                    imgList:[],
                    chengDan: {
                        h2:'承担',
                        p: '国家标准验证点（桥梁智能建造）\n国家强制性标准实施情况统计分析点（农作物种子）\n标准数字化技术与应用湖北省工程研究中心\n湖北省马术产业标准化工作组\n医疗服务标准化研究中心'
                    },
                    lianLuo: {
                        h2:'联络',
                        p: '全国资产管理标准化技术委员会资产管理数字化标准工作组'
                    },
                    hzjg: {
                        img:"/static/department/社会治理标准化研究所.jpeg"
                    },
                    hexinyewuList:[{
                        img:"/static/department/shzlbzh1.png"
                    },{
                        img:"/static/department/shzlbzh2.png"
                    },{
                        img:"/static/department/shzlbzh3.png"
                    },{
                        img:"/static/department/shzlbzh4.png"
                    }],
                }
            },{
                id: 5,
                icon: 'crown',
                item: '现代服务业与政府管理标准化研究所',
                departments: {
                    titleData:[{
                        h2:'从事',
                        p: '现代服务业和政府管理标准化研究及技术服务的专职部门。'
                    },{
                        h2:'致力于',
                        p: '专业领域标准化研究，涵盖理论研究、政策设计、成果应用及质量提升，涉及民政服务、食品安全、社会工作、志愿服务、医疗服务、住房和城乡建设、文化旅游、交通运输、减灾救灾和乡村建设等领域。'
                    }],
                    dutyListl:[
                        '申报承担公共服务和现代服务业标准化科研项目',
                        '开展有关部门标准化发展规划等政策研究',
                        '开展现代服务业和公共服务领域各级标准研制',
                        '面向各行业主管部门、地方政府和企事业单位提供标准化技术咨询服务'
                    ],
                    dutyListr:[
                        '探索开展各级标准的水平评价与实施效果评估等',
                        '为有关主管部门、行业协会、企业以及其他机构提供全套服务标准化解决方案',
                        '推动服务业标准化和社会管理与公共服务标准化工作在全社会广泛开展',
                    ],
                    imgList:[{
                        src:require('@/assets/image/content/page2_5_1.png'),
                        h3:'以标准助力养老服务质量',
                        p:'联合省民政局从 2014 年开始着手养老标准化建设， 先后出台了 《养老机构服务质量规范》、《养老机构 护理员日常服务操作规范》等5 项标准，对养老机构 每项服务的质量控制、改进作出了具体要求，对养老 机构护理员日常服务操作进行了规范，对各类服务 项目进行了程序化的规定。参与编写人社部培训教 材 《养老机构标准化实用手册》 和专著 《家庭农场 标准化实践》。'
                    },{
                        src:require('@/assets/image/content/page2_5_2.png'),
                        h3:'以“互联网+放管服”改革工作为基础助力“数字政府”建设',
                        p:'2017 年以来，省标质院抓紧落实省政府关于“互联网 + 放管服”改革的有关部署要求，精心组织，深入研 究，扎实推进行政审批标准化工作，为实现全省政务 服务“一张网”提供标准化技术支撑。研制发布 《行政 许可事项编码规则》、《行政许可事项服务指南编写规 范》、《行政许可事项审查细则编写规范》 等三项基础 标准为先导，相继确定了十大类行政权力和公共服务 事项标准化要素，统一了全省网上政务服务标准化工 作范式。配合省编办基本完成省市县乡四级清单体系 搭建，指导各地各部门编制录入服务指南和审查细 则，配合省政府开展政务服务“一张网”建设进度情况 月度考核通报。联合国家行政审批标准化工作组共同 研制省级地方标准 《行政许可事项网上办理质效评 价规范》，助力省市县乡村五级政务服务标准化全覆 盖。作为全省“数字政府”建设顶层设计专班成员，为 “数字政府”建设提供标准化技术支撑'
                    },{
                        src:require('@/assets/image/content/page2_5_3.png'),
                        h3:'支撑湖北机关事务管理标准化工作树立标杆',
                        p:'配合省机关事务局开展省市两级机关事务标准体系 建设方案设计，指导各处室和局属单位开展标准化 体系建设，推进重点职能领域地方标准研制，推动全 省机关事务系统开展国家级、省级标准化试点创建 工作。我省机关事务管理标准化工作先后获国务院 副秘书长、国管局局长李保荣，湖北省副省长黄楚平 高度评价，4 次在全国机关事务管理工作会上作典 型发言，被国管局作为“湖北经验”在全国机关事务 系统中推广。'
                    }],
                    chengDan: {
                        h2:'承担',
                        p: '湖北省民政服务标准化技术委员会秘书处\n湖北省民政服务标准化技术委员会养老服务标准化工作组秘书处'
                    },
                    hzjg: {
                        img:"/static/department/现代服务业与政府管理标准化研究所.jpeg"
                    },
                    hexinyewuList:[{
                        img:"/static/department/xdfwyyzfglbzh1.png"
                    },{
                        img:"/static/department/xdfwyyzfglbzh2.png"
                    },{
                        img:"/static/department/xdfwyyzfglbzh3.png"
                    },{
                        img:"/static/department/xdfwyyzfglbzh4.png"
                    }],
                }
            },{
                id: 6,
                icon: 'global',
                item: '国际标准化研究所',
                departments: {
                    titleData:[{
                        h2:'从事',
                        p: '技术性贸易措施、检验检测以及国际标准化领域研究和应用的专职部门。'
                    },{
                        h2:'致力于',
                        p: '技术性贸易措施应对、国际标准化交流合作与培训、检验检测宏观与技术研究、科普标准化、标准化促县域经济发展的应用研究以及湖北省标准化学会运维管理。'
                    }],
                    dutyListl:[
                        '负责申报承担 WTO 技术性贸易措施领域的科研项目',
                        '负责与国际标准化组织保持联络、推动国际标准化合作',
                        '负责申报承担检验检测领域的科研项目',
                        '负责检验检测领域各级标准申报与研制'
                    ],
                    dutyListr:[
                        '负责湖北省标准化学会运维管理、负责湖北省标准化学会团体标准 发布与管理',
                        '负责面向各行业主管部门、地方政府和企事业单位提供标准化技术 咨询服务',
                        '负责探索开展团体标准的发展模式、水平评价与实施效果评估等， 为有关主管部门、行业协会、企业以及其他机构提供全套团体标准化 服务，推动团体标准化工作在全社会广泛开展',
                    ],
                    imgList:[{
                        src:require('@/assets/image/content/page2_6_1.png'),
                        h3:'以试点推动城市与国际可持续发展标准接轨',
                        p:'在省标质院的全力推动以及咸宁市委市政府的大力 支持下，咸宁市与 2017 年成功获批成ISO37101 试点城市。2017 年 6 月 30 日，咸宁市政府代表在 杭州和来自法国雷恩市、英国格拉斯米尔市、巴西隆 德里纳市、阿联酋阿布扎比市、中国杭州、深圳、青岛等 16 个国际、国内城市的市长或市长特使，共同发起成立 了 “全球可持续发展标准化城市联盟”，正式加入试点 工作。湖北省标质院持续为咸宁市试点工作提供标准 技术支撑，帮助梳理了咸宁市在促进竹林经济可持 续生产和消费的工作情况，编写成为咸宁案例，案例 被收入 《城市可持续发展案例集》，以中英文版本在 全球多个国家发布。'
                    },{
                        src:require('@/assets/image/content/page2_6_2.png'),
                        h3:'以“互联网+以标准国际合作推动激光产业全球品牌打造',
                        p:'为了提升湖北激光产业面向国内和国际市场的中高端 供给，进一步打造湖北激光品牌，培育在全球具有竞 争力的优势激光产业集群，创新基地与 IEEE 签署 合作备忘录，手把手帮助湖北激光企业从提交国际 标准提案开始，到 《IEEE P2066 大功率工业激光 器安全规范》 和《IEEE P2067 光纤激光器参数要 求和测试方法》两项由湖北激光企业主导的新国际 标准在 2018 年“中国光谷”国际光电子博览会暨论 坛上正式启动制定工作。两项标准将推动湖北激光 产业进入国际激光行业技术规则制定圈，加深激光 技术交流合作的同时，提升湖北激光产业的技术 话语权和国际知名度。'
                    },{
                        src:require('@/assets/image/content/page2_6_3.png'),
                        h3:'以标准中英合作布局智慧社区产业发展前端',
                        p:'为助推湖北智慧建筑企业将先进技术和项目经验转 化为产业发展前端优势，创新基地联合智慧社区标 准化领域的全球领先机构英国标准协会 （BSI），与 中建三局智能技术有限公司合作开展智慧社区管理 框架 PAS 标准制定项目，项目被纳入中英两国标准 合作框架，并已带动中建三局智能技术有限公司在 中英标准合作的平台上，实现更广泛的资源共享和 行业互动。随着项目的推进和完成，公司将在智慧社区 国际规则领域享更多话语权，公司的智慧社区技术 也将因此获得更为广泛的国际市场认可。'
                    }],
                    chengDan: {
                        h2:'承担',
                        p: '湖北WTO/TBT通报咨询中心\n大洋洲标准化（湖北）研究中心\n湖北高新技术产业标准化国际科技合作基地\n湖北省标准化学会\n东湖高新区（武汉自贸区）进出口企业服务联盟标准化专委会'
                    },
                    hzjg: {
                        img:"/static/department/国际标准化研究所.jpeg"
                    },
                    hexinyewuList:[{
                        img:"/static/department/gjbzhyjs1.png"
                    },{
                        img:"/static/department/gjbzhyjs2.png"
                    },{
                        img:"/static/department/gjbzhyjs3.png"
                    },{
                        img:"/static/department/gjbzhyjs4.png"
                    }],
                }
            },{
                id: 7,
                icon: 'fork',
                item: '质量发展与质量安全研究所',
                departments: {
                    titleData:[{
                        h2:'从事',
                        p: '质量发展与质量安全研究及技术服务的专职部门。'
                    },{
                        h2:'致力于',
                        p: '围绕省委省政府质量强省、品牌强省战略，开展宏观质量管理、中观质量发展和微观质量提升的顶层政策设计、前沿理论研究、数据统计分析、标准制修订等技术支撑和市场服务工作。\n为湖北开展缺陷产品发现、调查、确认和召回等管理工作提供技术支持。\n开展湖北省家用汽车产品三包相关法律法规宣贯和实施效果分析。'
                    }],
                    dutyListl:[
                        '开展政府质量监管、质量安全风险预警与评估、质量激励等宏观质 量政策研究与服务工作',
                        '开展产业质量发展战略规划与实施的研究和技术服务工作',
                        '开展先进质量管理方法的研究与实践工作，服务于企业追求卓越绩 效，为企业质量管理技术和水平提升、产品质量安全控制、规避召 回风险提供技术咨询和解决方案',
                        '基于维护市场公平竞争秩序，传递有效质量信息数据，开展质量数据统计分析与应用的研究工作'
                    ],
                    dutyListr:[
                        '负责湖北省标准化学会运维管理、负责湖北省标准化学会团体标准 发布与管理',
                        '负责缺陷产品召回的日常业务和技术服务、缺陷产品管理法规与 基本知识的宣传培训以及缺陷产品管理理论与实践的研究工作， 包括产品质量安全信息的收集分析、缺陷产品调查确认、缺陷产品 召回实施跟踪与监督等',
                        '负责湖北省家用汽车产品三包责任争议调解专家库的组建、维护与 管理工作',
                    ],
                    imgList:[{
                        src:require('@/assets/image/content/page2_7_1.png'),
                        h3:'以召回工作推动产品质量提升',
                        p:'对我省生产的儿童用品实行缺陷信息重点监测，缺 陷问题实时分析，缺陷产品强抓召回。累计召回我省 童车产品1.8万余量，为消费者挽回损失500多万元。 按照“召回一种产品，提升一个产业”的工作思路，针 对我省25家童车企业开展质量诊断，分析缺陷产生 原因，制定改进方案，帮助多家企业导入6S管理，开展欧 盟认证的指导和培训，实现产品合格率提升。'
                    },{
                        src:require('@/assets/image/content/page2_7_2.png'),
                        h3:'以“标尺”助推高质量发展',
                        p:'承担原湖北省质量技术监督局政研课题，在高质量发 展背景下，构建湖北省宏观质量指数评估体系。 强化新发展理念，全面分析、客观筛选，立足省情实际 和阶段性特征，构建能够客观反映湖北省质量水平的 宏观质量指数指标体系，从质量基础、质量安全、质量 发展三个维度，确定12个二级指标及54个三级指标， 同时借助数学模型，运用指数化形式，对湖北省17个地 市州的质量发展水平进行实证分析。 在指标设定中摒弃单一追求“速度快”和“规模大”的 理念，关注发展的潜力、成本和生态，为新阶段湖北省 监测评估区域质量发展水平、进而看清不足主动校航 提供了量化工具和标杆引领。'
                    },{
                        src:require('@/assets/image/content/page2_7_3.png'),
                        h3:'以三包政策化解我省汽车产品质量纠纷',
                        p:'贯彻执行家用汽车三包制度，为经营者与消费者发 生产品质量争议提供调解处理渠道；收集共性产品 安全故障线索，为缺陷汽车召回提供信息支撑。 成立三包投诉受理工作组，制定调解处理标准程序， 与省消委秘书处协同处理重大质量问题投诉，组建聘任 126名的三包专家技术团队，组织经营者召开三包政 策宣贯会。累计化解汽车产品质量投诉1500余起，上 报缺陷线索50余起，消费者处理满意度100%。 三包调解保障了消费者的合法权益，促进了汽车消费 市场的和谐稳定，提升了我省汽车行业售后服务质量 总体水平。'
                    }],
                    chengDan: {
                        h2:'承担',
                        p: '湖北省缺陷产品管理中心\n“湖北精品”认定委员会秘书处'
                    },
                    hzjg: {
                        img:"/static/department/质量发展与质量安全研究所.jpeg"
                    },
                    hexinyewuList:[{
                        img:"/static/department/zlfzyzlaq1.png"
                    },{
                        img:"/static/department/zlfzyzlaq2.png"
                    },{
                        img:"/static/department/zlfzyzlaq3.png"
                    },{
                        img:"/static/department/zlfzyzlaq4.png"
                    },{
                        img:"/static/department/zlfzyzlaq5.png"
                    }],
                }
            },{
                id: 8,
                icon: 'sliders',
                item: '资源环境与绿色发展标准化研究所',
                departments: {
                    titleData:[{
                        h2:'从事',
                        p: '资源环境与绿色发展标准化研究及技术服务的专职部门。'
                    },{
                        h2:'致力于',
                        p: '能源、环保、水资源、循环经济、绿色发展、农业农村以及经营主体信用等领域标准化前沿理论、顶层政策设计以及标准化成果的应用研究。'
                    }],
                    dutyListl:[
                        '负责申报承担资源与环境领域标准化科研项目',
                        '负责开展有关部门标准化发展规划等政策研究',
                        '负责开展资源与环境领域各级标准研制',
                        '负责面向各行业主管部门、地方政府和企事业单位提供标准化技术 咨询服务'
                    ],
                    dutyListr:[
                        '负责探索开展各级标准的水平评价与实施效果评估等，为有关主管 部门、行业协会、企业以及其他机构提供全套服务标准化解决方案， 推动资源与环境标准化工作在全社会广泛开展',
                        '负责开展工业固体废物资源综合利用评价工作',
                        '负责开展绿色制造体系评价工作',
                    ],
                    imgList:[{
                        src:require('@/assets/image/content/page2_8_1.png'),
                        h3:'以标准支撑绿色咸宁发展',
                        p:'为了固化绿色咸宁建设的试点经验和成果，加快推进绿色咸宁建设，为其它示 范区建设经验和模式的“可复制、可推广”提供有效载体，为全省生态文明建设 提供操作指南，按照“绿色城镇、绿色产业、绿色环境”三位一体的思路，协助咸 宁市建立了“绿色咸宁”标准化体系框架，收集整理了 757个相关标准。“咸宁标 准”对促进绿色产业发展作用显著，尤其在现代农业、生态保护和公共服务标准 体系建设上取得较好的效果，制定农业地方标准 43 项，先后创建 1 个国家级有 机产品示范市，14 个国家级农业标准化示范区，9 个省级标准化示范区，“三品 一标”农产品达到 441 个。2018 年咸宁市空气质量在全国 169 个重点城市中 位居第 19 为，是全省唯一入选“20 个空气质量相对较好城市”的地级市。实施 公共交通安全、节能、环保等强制性标准，成为中部六省第一个实现全部纯电动 公交运营的城市，是全国首个国家级城市公共交通标准化试点市'
                    },{
                        src:require('@/assets/image/content/page2_8_2.png'),
                        h3:'以标准规范湖北省光伏产业发展',
                        p:'为了落实国家能源局发布的 《关于可再生能源发展“十三五规划实施的指导 意见”》，促进湖北省光伏产业的合作与发展，推动建设完整的产业链和健康、 成熟的市场，提升产业的整体竞争力，创新基地积极开展产学研协作的创新合 作模式，联合全国 28 家光伏产业上下游机构成立了湖北省光伏产业标准联 盟，发布实施一项团体标准 《湖北省光伏扶贫电站建设验收规范》，填补了我省 在光伏扶贫电站建设验收领域的空白，对规范我省光伏电站特别是扶贫电站 的建设提供了参考。'
                    }],
                    chengDan: {
                        h2:'承担',
                        p: '湖北省碳达峰碳中和标准化技术委员会\n湖北省能源标准化技术委员会\n湖北省生态环境标准化技术委员会\n湖北省绿色能源装备标准联盟\n湖北省光伏产业标准联盟\n湖北省团体标准化发展联盟'
                    },
                    hzjg: {
                        img:"/static/department/资源环境与绿色发展标准化研究所.jpeg"
                    },
                    hexinyewuList:[{
                        img:"/static/department/zyhjylsfzbzh1.png"
                    },{
                        img:"/static/department/zyhjylsfzbzh2.png"
                    },{
                        img:"/static/department/zyhjylsfzbzh3.png"
                    },{
                        img:"/static/department/zyhjylsfzbzh4.png"
                    }],
                }
            },{
                id: 9,
                icon: 'code-sandbox',
                item: '商贸流通与供应链标准化研究所',
                departments: {
                    titleData:[{
                        h2:'从事',
                        p: '商贸流通与供应链标准化研究及技术服务的专职部门。'
                    },{
                        h2:'致力于',
                        p: '供应链、物流、商贸流通、新能源交通、知识产权、特种设备等关键领域和三产融合发展前沿理论、顶层政策设计以及标准化成果的应用研究。'
                    }],
                    dutyListl:[
                        '负责省物标委秘书处的日常工作运转',
                        '负责申报承担商贸、物流、交通行业的标准化与质量提升科研项目',
                        '负责开展有关部门标准化发展规划等政策研究',
                        '负责开展商贸、物流、交通行业各级标准研制'
                    ],
                    dutyListr:[
                        '负责探索开展各级标准的水平评价、实施效果评估、宣贯培训等',
                        '负责面向各行业主管部门、地方政府和企事业单位提供标准化 技术咨询服务',
                        '为有关主管部门、行业协会、企业以及其他机构提供全套标准化 解决方案',
                        '· 推动标准化与质量提升工作在全社会广泛开展'
                    ],
                    imgList:[{
                        src:require('@/assets/image/content/page2_9_1.png'),
                        h3:'全国物流标准化城市 （黄石市）产品质量试点技术支撑提升',
                        p:'2016年开始，协助黄石市商务委员会、质监局、财政局完善国家试点项目整体方案，参与黄 石市三单位组织的试点企业2轮立项评审与结项评审工作。 2017年，协助试点企业申报立项、研制5项省级物流地方标准，涉及城市配送、鞋服仓储、医 药仓储、公路运输、玻璃容器包装储运领域。承担《黄石市商贸物流标准体系研究》政采项 目，汇总现行国标、行标与新研制的企标共计300余项。协助湖北浙联鞋服公司、湖北众联物 流公司完成仓库功能分区、划线与施工工作；指导劲酒公司、劲鹏制盖公司、浙联鞋服公司 按照包装标准模数改善产品外包装盒。 通过物流标准化的宣贯与持续实施改进，黄石市经济与社会效益显著，综合物流成本下降 3.91%，平均货损率降低0.15%，物流装卸效率平均提高2.02倍，车辆周转效率提高2.39倍 以上，装卸搬运单位成本下降45%。'
                    },{
                        src:require('@/assets/image/content/page2_9_2.png'),
                        h3:'以标准助推冷链、快递业高质量发展',
                        p:'2016年，与武汉山绿农产品集团共同研制地标 《冷链物流中木质托盘使用标准》，进一步提 升国标托盘的高效使用；协助湖北潜网龙虾贸易有限公司研制地标 《小龙虾冷链物流服务 标准》，加快打造我省小龙虾产业品牌。 2016年，协助湖北顺丰速递公司与武汉德邦物流公司申报立项并创建湖北省服务业标准化 试点项目，构建企业物流标准体系，指导标准有效实施并持续性改进。 2018年共同研制地标 《公路零担运输服务规范》、申报立项商务部行业标准 《城乡配送服 务质量规范》，助推我省快递业服务高质量发展。'
                    }],
                    chengDan: {
                        h2:'承担',
                        p: '湖北省物流标准化技术委员会秘书处'
                    },
                    hzjg: {
                        img:"/static/department/商贸流通与供应链标准化研究所.jpeg"
                    },
                    hexinyewuList:[{
                        img:"/static/department/smltygylbzh1.png"
                    },{
                        img:"/static/department/smltygylbzh2.png"
                    },{
                        img:"/static/department/smltygylbzh3.png"
                    },{
                        img:"/static/department/smltygylbzh4.png"
                    }],
                }
            },{
                id: 10,
                icon: 'project',
                item: '事业发展部',
                departments: {
                    titleData:[{
                        h2:'从事',
                        p: '院级层面业务战略落地执行，业务资源统筹协调，业务质效优化提升，客户资源拓展维护的部门。'
                    },{
                        h2:'致力于',
                        p: '新领域、新业态、新服务模式的开拓探索；\n重点区域、重点产业、重点客户的挖掘拓展；\n业务资源的集中统筹；\n经营项目的全过程管理；\n地方标准技术审评的全方位支撑。'
                    }],
                    dutyListl:[
                        '负责市场开发、拓展、各业务部门商务信息收集整理、经济类收入合同的日常管理，合同登记'
                    ],
                    dutyListr:[],
                    imgList:[],
                    chengDan: {
                        h2:'承担',
                        p: '湖北省标准技术审评中心'
                    },
                    hzjg: {
                        img:"/static/department/事业发展部.jpeg"
                    },
                    hexinyewuList:[{
                        img:"/static/department/syfzb1.png"
                    },{
                        img:"/static/department/syfzb2.png"
                    },{
                        img:"/static/department/syfzb3.png"
                    }],
                }
            },{
                id: 11,
                icon: 'rise',
                item: '标准化理论与战略研究所',
                departments: {
                    titleData:[{
                        h2:'从事',
                        p: '科研管理、标准化基础理论研究、标准化政策研究与战略规划的专职部门。'
                    },{
                        h2:'致力于',
                        p: '国内外标准化发展战略与政策规划、体制机制、标准化体系研究；\n承担国家、省级政府标准和标准化相关政策的前瞻性研究及相关提案的编写；\n承担院内科研管理，新领域、新业态、新模式标准化服务路线研究；\n支撑标准化法律法规、战略规划研制以及促进标准成果转化等工作。'
                    }],
                    dutyListl:[
                        '负责市场开发、拓展、各业务部门商务信息收集整理、经济类收入合同的日常管理，合同登记'
                    ],
                    dutyListr:[],
                    imgList:[],
                    chengDan: {
                        h2:'承担',
                        p: '院内学术委员会'
                    },
                    hzjg: {
                        img:"/static/department/标准化理论与战略研究所.jpeg"
                    },
                    hexinyewuList:[{
                        img:"/static/department/bzhllyzl1.png"
                    },{
                        img:"/static/department/bzhllyzl2.png"
                    }],
                }
            }],
            departments: {},
            imgListIndex: 0,
            imgListObj:{}
        }
    },
    created(){
        const i = this.$route.params.id?Number(this.$route.params.id):1
        this.defaultSelectedKeys = [i]
        this.departments = this.menuData[i-1].departments
    },
    methods:{
        handleClick({key}){
            this.departments = this.menuData.filter(item => item.id == key)[0].departments
            this.imgListIndex = 0
            this.imgListObj = this.departments.imgList[0]
        },
        imgListClick(i, o){
            this.imgListIndex = i
            this.imgListObj = o
        },
        toggleCollapsed(){
            this.collapsed = !this.collapsed;
        },
    }
}
</script>

<style lang="less" scoped>
.association{
    width: 80%;
    margin: auto;
    margin-top: 80px;
    padding-bottom: 40px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: #fff;
    .association_r{
        padding: 40px 60px;
        width: 90%;
        .department{
            margin-bottom: 50px;
            img.fixedWidth {
                width: 60%;
                margin-top: 30px;
            }
            >h2{
                color: #ec7171;
                font-size: 30px;
            }
            .line{
                width: 100%;
                height: 2px;
                background: #ec7171;
            }
            .title{
                >div h2{
                    color: #ec7171;
                    font-size: 22px;
                }
                >div p{
                    white-space: pre-line;
                }
            }
            .dutyList{
                display: inline-block;
                width: 50%;
                vertical-align: top;
                .title{
                 h2{
                    color: #ec7171;
                    font-size: 22px;
                }
                 p{
                    white-space: pre-line;
                }
            }
            }
            .dutyListl{
                border-right: 1px dashed rgb(153, 153, 153);
                padding-right: 40px;
            }
            .dutyListr{
                padding-left: 40px;
            }
            .imgList{
                display: flex;
                justify-content: space-around;
                padding: 20px 0;
                .selectedSty{
                    box-shadow: 0 0 30px rgba(0,0,0,.5);
                    transform: scale(1.1);
                }
            }
            h3{
                color: #ec7171;
                font-size: 24px;
            }
            
        
        }
    }
    .collapsedBT{
        width: 200px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

</style>